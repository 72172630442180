.App {
    /*text-align: center;*/
}

.App-logo-loader {
    width: 100%;
    text-align-all: left;
    height: 20vmin;
    pointer-events: none;
    left: 2px;
}

.App-logo {
    text-align-all: left;
    height: 10vmin;
    pointer-events: none;
    left: 2px;
}

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

.App-header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
    font-size: calc(10px + 2vmin);
    color: #282c34;
}

.App-link {
    color: #61dafb;
}

.doc-api {
    padding-left: 20px;
    padding-right: 20px;

}

.doc-api h1 {
    color: #204788
}

.doc-api h2 {
    color: #204788
}

.doc-api h3 {
    color: #204788
}

.doc-api pre {
    tab-size: 4;
    counter-reset: linecounter;
    padding: 0;
    color: #eee;
    background-color: #333;
    font-size: 16px;
    line-height: 16px;
}

.doc-api pre span.line {
    counter-increment: linecounter;
    line-height: 16px;
}

.doc-api pre span.line::before {
    content: counter(linecounter);
    color: red;
    width: 30px;
    display: inline-block;
    border-right: 1px dotted #ccc;
    padding-right: 3px;
    text-align: right;
    font-size: 16px;
    line-height: 16px;
}

.doc-api pre span.line:nth-child(odd)::before {
    background-color: #555;
}

.download-cli {
    border: 1px solid #204788;
    color: #204788;
    font-weight: normal;
    font-size: large;
    margin-left: 0.5em;
    padding: 4px 8px 4px;
    display: inline-block;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: pointer;
    background-color: white;
}
